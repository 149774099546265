import Alpine from 'alpinejs';
import 'lazysizes';

if(import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log("HMR");
    });
}

// Setup Alpine.js
window.Alpine = Alpine
Alpine.start();